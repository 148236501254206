import React, { useState, useEffect } from 'react';
import Datas from "../../assets/ProjetsDatas";
import github from "../../assets/ProjetsPhotos/github.svg"
import eye from "../../assets/ProjetsPhotos/eye-check-lined.svg"

export default function Gallery() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='gallery-wrapper'>
            <h3>PROJETS</h3>
            {Datas.map(data => {
                const imageToShow = windowWidth < 768 ? data.small : (windowWidth < 1440 ? data.medium : data.large);

                return (
                    <div key={data.id} className="gallery-item">
                        <div className="image-overlay-wrapper">
                            <img src={imageToShow} alt={data.title} className="project-image" />
                            <div className="overlay">
                                <a href={data.githubLink} target="_blank" rel="noreferrer"><img src={github} alt="GitHub" className="overlay-image" /></a>
                                <a href={data.onlineLink} target="_blank" rel="noreferrer"><img src={eye} alt="Projet en Ligne" className="overlay-image" /></a>
                            </div>
                        </div>
                        <div className="card-content">
                            <h3>{data.title}</h3>
                            <p>{data.description}</p>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

