import Accueil from './pages/Accueil';
import Error from './pages/Error';


import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';

const router = createBrowserRouter([
	{
		path: "/",
		element: <Accueil />
	},
	{
		path: '/Error',
		element: <Error />
	},
    {
        path: "*",
		element: <Navigate to="/Error" replace />
    }
]);

function App() {
	return (
		<>
			<RouterProvider router={router}/>
		</>
	);
}

export default App;