import Animation from '../animation'
import githubIcon from "../../assets/ProjetsPhotos/github.svg";
import linkedinIcon from "../../assets/ProjetsPhotos/linkedin.svg";

 
function Header({image}) {
    return (
        <div className="navContainer">
            <div className="boiteLogo">
                <img className="name-logo" src={image} alt="logo" /> 
            </div>
            <Animation></Animation>
            <div className="socialIcons2">
                <a href="https://github.com/helia57" target="_blank" rel="noopener noreferrer">
                    <img src={githubIcon} alt="GitHub" />
                </a>
                <a href="https://www.linkedin.com/in/elia-de-fays-082917173/?originalSubdomain=fr" target="_blank" rel="noopener noreferrer">
                    <img src={linkedinIcon} alt="LinkedIn" />
                </a>
            </div>
        </div>
    )
}

export default Header