import bookiSmall from '../../assets/imageProjet/small/bookir.webp';
import bookiMedium from '../../assets/imageProjet/medium/bookir_medium .webp';
import bookiLarge from '../../assets/imageProjet/large/bookir .webp';

import bluelSmall from '../../assets/imageProjet/small/Sophie Bluel.webp';
import bluelMedium from '../../assets/imageProjet/medium/Sophie Bluel_medium .webp';
import bluelLarge from '../../assets/imageProjet/large/Sophie Bluel .webp';

import mvgrimoireSmall from '../../assets/imageProjet/small/Mon vieux grimoire.webp';
import mvgrimoireMedium from '../../assets/imageProjet/medium/Mon vieux grimoire_medium .webp';
import mvgrimoireLarge from '../../assets/imageProjet/large/Mon vieux grimoire .webp';

import kasaSmall from '../../assets/imageProjet/small/kasa.webp';
import kasaMedium from '../../assets/imageProjet/medium/kasa_medium .webp';
import kasaLarge from '../../assets/imageProjet/large/kasa .webp';

const Datas = [
    {
        "id": "c67ab8a7",
        "title": "projet Booki",
        "cover": bookiSmall,
        "githubLink" :"https://github.com/helia57/booki-starter-code-master",
        "onlineLink":"https://helia57.github.io/booki-starter-code-master/",
        "description": "Plateforme de réservation d'une agence de voyage permettant aux utilisateurs de découvrir et réserver des hébergements ainsi que des activités dans la ville de leur choix.",
        "small": bookiSmall,
        "medium": bookiMedium,
        "large": bookiLarge
    },
    {
        "id": "2139a317",
        "title": "Sophie Bluel",
        "cover": bluelSmall,
        "githubLink" :"https://github.com/helia57/Portfolio-sophie-bluel",
        "onlineLink":"https://eliadefays.fr/ProjetSB/index.html",
        "description": "Site portfolio d'une architecte d'intérieur avec une interface sécurisée facilitant le dépôt de projets. Mettez en valeur votre créativité et partagez vos réalisations de manière intuitive et sécurisée.",
        "small": bluelSmall,
        "medium": bluelMedium,
        "large": bluelLarge
    },
    {
        "id": "6ff132c6",
        "title": "Mon Vieux Grimoire",
        "cover": mvgrimoireSmall,
        "githubLink" :"https://github.com/helia57/mon-vieux-grimoire",
        "onlineLink":"https://helia57.github.io/projet-kasa",
        "description": "Plateforme communautaire sous forme d'application web permettant la découverte, notation en ligne, et partage de livres. Avec une connexion sécurisée, les membres peuvent ajouter, modifier, ou supprimer des livres. Le site met en avant les livres les mieux notés, offrant une expérience interactive et collaborative pour les passionnés de lecture.",
        "small": mvgrimoireSmall,
        "medium": mvgrimoireMedium,
        "large": mvgrimoireLarge
    },
    {
        "id": "cb02d69b",
        "title": "Projet Kasa",
        "cover": kasaSmall,
        "description": "Kasa est une application de gestion immobilière offrant un découpage en composants modulaires réutilisables pour chaque ajout de logements. Chaque composant est proposé aussi en lecture seule en utilisant efficacement les props pour des communications inter-composants. La gestion des états dans les composants, l'interaction avec les événements, ainsi que la gestion des paramètres des routes via React Router sont également intégrées dans cette application.",
        "githubLink" :"https://github.com/helia57/projet-kasa",
        "onlineLink":"https://helia57.github.io/projet-kasa",
        "small": kasaSmall,
        "medium": kasaMedium,
        "large": kasaLarge
    }
]

export default Datas;
