
import Gallery from "../../components/Gallery"
import Cards from "../../components/Cards"
import Banner from "../../components/Banner"
// import images
import clavierSmall from "../../assets/ProjetsPhotos/clavier small .webp"
import clavierMedium from "../../assets/ProjetsPhotos/clavier Medium .webp"
import clavierLarge from "../../assets/ProjetsPhotos/clavier large .webp"
import arbre from "../../assets/ProjetsPhotos/arbre .svg"


// import composant
import Collapse from "../../components/Collapse"
import Header from "../../components/Header"
import Footer from "../../components/Footer"

import ImageWithModals from "../../components/ImageWithModals"
import Carousel from "../../components/Carousel"


function Accueil() {
  const aboutDatas = [
		{
			"id": "1",
			"title": " Compétences FRONT",
			"content": [ 
			
				" Intégrer du contenu conformément à une maquette et implémenter une interface responsive avec HTML et CSS",
				" Développer, manipuler les éléments du DOM,ou bien récupérer les données utilisateurs via des formulaires avec JavaScript",
				" Initialiser  une application avec Create React App, configurer la navigation de l'application avec React Router et développer l'interface d'un site web grâce à des composants React"
		]
		},
		{
			"id": "2",
			"title": " Compétences de suivi de projet",
			"content": [ 
				
				" Mettre en place une méthode de veille technologique",
				" Rédiger les spécifications techniques d'un projet web à partir de besoins fonctionnels",
				" Découper des fonctionnalités en tâches pour préparer le développement en mode agile",
				" Présenter une solution technique, Suivre le déroulement du projet grâce à un outil de gestion de projet, versionner son projet avec Git et Github puis, déployer un site statique en ligne"
				
		]
		},
		{
			"id": "3",
			"title": " Compétences BACK et qualité web",
			"content": [ 
				
				" Stocker des données de manière sécurisée et mettre en place une authentification forte",
				" Développer un back-end avec NodeJS",
       			" Optimiser les performances d'un site web ( performance, seo, accessibilité) ou débugger un site web ",
				" Rédiger un cahier de recette pour tester un site ou vérifier la qualité d'un code"
		]
		},
		
		
	]

  
    return (
			
      <>
	  	<Header image={arbre}/>
        <main>
		<Banner
                image={{
                    small: clavierSmall,
                    medium: clavierMedium,
                    large: clavierLarge,
					alt:"image clavier avec un paysage",
					fetchpriority: "high"
					
					
				}}
                text2=" Bienvenue sur mon portfolio. Elia DE FAYS, Web developpeuse basée en Moselle, France."
        /> 
			
			<Carousel></Carousel>
          
          <div className='about_main'>
            {aboutDatas.map(data => {
              return (
                <div key={data.id} className="about_main_collapse">
                  <Collapse style={{width:'790px',}}  title={data.title} content={data.content} />
                </div>
              )}
            )}  
          </div>
		  <ImageWithModals></ImageWithModals>
              
          <Gallery>
            <Cards></Cards>
          </Gallery>
          
        </main>
        <Footer />  
      </>
    )
}
  

export default Accueil