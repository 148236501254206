import React, { useState, useEffect } from 'react';
import Logo_datas from "../../assets/Logo_datas"; 

function getSizeAppropriateImage(image) {
    const screenWidth = window.innerWidth;
  
    if (screenWidth >= 1440) {
      return image.large;
    } else if (screenWidth >= 768) {
      return image.medium;
    } else if (screenWidth >= 425) {
      return image.small;
    } else {
      // Si aucune condition n'est satisfaite, retourne par défaut l'image de petite taille
      return image.small;
    }
  }
  
  function ScrollingBanner() {
    const [currentIndex, setCurrentIndex] = useState(0);
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        setCurrentIndex(prevIndex =>
          prevIndex === Logo_datas.length - 1 ? 0 : prevIndex + 1
        );
      }, 1000); // Intervalle de défilement en millisecondes
  
      return () => clearInterval(intervalId);
    }, []);
  
    return (
      <div className="scrolling-banner">
        <div className="scrolling-content">    
          {Logo_datas.map((data, index) => (
            <div
              key={index}
              className={`scrolling-slide ${
                index === currentIndex ? 'active' : ''
              }`}
            >
              <img
                className="logos"
                src={getSizeAppropriateImage(data.images)}
                width={getSizeAppropriateImage(data.images).width}
                height={getSizeAppropriateImage(data.images).height}
                alt={`Slide ${index}`}
                loading="lazy"
              />
              <p className="logos-Description">{data.description}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }
  
  export default ScrollingBanner;
  