import React, { useState, useEffect } from 'react';



function Banner({ image, text2 }) {
    const [windowWidth, setWindowWidth] = useState(0); // Initial width 0

    useEffect(() => {
        // Function to update window width
        const updateWindowWidth = () => {
            setWindowWidth(window.innerWidth);
        };

        // Call the function once initially
        updateWindowWidth();

        // Add event listener to update width on resize
        window.addEventListener('resize', updateWindowWidth);

        // Cleanup function to remove event listener
        return () => {
            window.removeEventListener('resize', updateWindowWidth);
        };
    }, []);

    let imageToShow;

    // Check window width and assign appropriate image
    if (windowWidth < 425) {
        imageToShow = image.small;
    } else if (windowWidth < 768) {
        imageToShow = image.medium;
    } else {
        imageToShow = image.large;
    }

    return (
        <div className="containerCard">
            <div className="wrapper-welcome">
                <img className="presentation_image" src={imageToShow} fetchpriority="high" alt="presentation" />
                <h2>{text2} </h2> 
            </div>
        </div>
    );
}

export default Banner;

