
import htmlLarge from "../../assets/logo/large/html5.svg";
import htmlMedium from "../../assets/logo/medium/html5.svg";
import htmlSmall from "../../assets/logo/small/html5.svg";

import cssLarge from "../../assets/logo/large/css3.svg";
import cssMedium from "../../assets/logo/medium/css3.svg";
import cssSmall from "../../assets/logo/small/css3.svg";

import javascriptLarge from "../../assets/logo/large/javascript.svg";
import javascriptMedium from "../../assets/logo/medium/javascript.svg";
import javascriptSmall from "../../assets/logo/small/javascript.svg";

import phpLarge from "../../assets/logo/large/php.svg";
import phpMedium from "../../assets/logo/medium/php.svg";
import phpSmall from "../../assets/logo/small/php.svg";

import reactLarge from "../../assets/logo/large/react.svg";
import reactMedium from "../../assets/logo/medium/react.svg";
import reactSmall from "../../assets/logo/small/react.svg";

import vueLarge from "../../assets/logo/large/vue.svg";
import vueMedium from "../../assets/logo/medium/vue.svg";
import vueSmall from "../../assets/logo/small/vue.svg";

import nodejsLarge from "../../assets/logo/large/nodejs.svg";
import nodejsMedium from "../../assets/logo/medium/nodejs.svg";
import nodejsSmall from "../../assets/logo/small/nodejs.svg";

import MySqlLarge from "../../assets/logo/large/mysql.svg";
import MySqlMedium from "../../assets/logo/medium/mysql.svg";
import MySqlSmall from "../../assets/logo/small/mysql.svg";

import mongodbLarge from "../../assets/logo/large/mongodb.svg";
import mongodbMedium from "../../assets/logo/medium/mongodb.svg";
import mongodbSmall from "../../assets/logo/small/mongodb.svg";

import sassLarge from "../../assets/logo/large/sass.svg";
import sassMedium from "../../assets/logo/medium/sass.svg";
import sassSmall from "../../assets/logo/small/sass.svg";

import jquerryLarge from "../../assets/logo/large/jquerry.svg";
import jquerryMedium from "../../assets/logo/medium/jquerry.svg";
import jquerrySmall from "../../assets/logo/small/jquerry.svg";

import boostrapLarge from "../../assets/logo/large/boostrap.svg";
import boostrapMedium from "../../assets/logo/medium/boostrap.svg";
import boostrapSmall from "../../assets/logo/small/boostrap.svg";

import typescriptLarge from "../../assets/logo/large/typescript.svg";
import typescriptMedium from "../../assets/logo/medium/typescript.svg";
import typescriptSmall from "../../assets/logo/small/typescript.svg";


// Importez d'autres images de la même manière

const Logo_datas = [
  {
    id: "01",
    description: "HTML 5",
    images: {
      large: htmlLarge,
      medium: htmlMedium,
      small: htmlSmall,
      fetchpriority: "high"
    }
  },
  {
    id: "02",
    description: "CSS 3",
    images: {
      large: cssLarge,
      medium: cssMedium,
      small: cssSmall,
      fetchpriority: "high"
    }
  },
  {
    id: "03",
    description: "Javascript",
    images: {
      large: javascriptLarge,
      medium: javascriptMedium,
      small: javascriptSmall,
      fetchpriority: "high"
    }
  },
  {
    id: "04",
    description: "PHP",
    images: {
      large: phpLarge,
      medium: phpMedium,
      small: phpSmall,
      fetchpriority: "high"
    }
  },
  {
    id: "05",
    description: "React",
    images: {
      large: reactLarge,
      medium: reactMedium,
      small: reactSmall,
      fetchpriority: "high"
    }
  },
  {
    id: "06",
    description: "VUE.js",
    images: {
      large: vueLarge,
      medium: vueMedium,
      small: vueSmall,
      fetchpriority: "high"
    }
  },
  {
    id: "07",
    description: "Node.js",
    images: {
      large: nodejsLarge,
      medium: nodejsMedium,
      small: nodejsSmall,
    }
  },
  {
    id: "08",
    description: "MySql",
    images: {
      large: MySqlLarge,
      medium: MySqlMedium,
      small: MySqlSmall,
    }
  },
  {
    id: "09",
    description: "MongoDB",
    images: {
      large: mongodbLarge,
      medium: mongodbMedium,
      small: mongodbSmall,
    }
  },
  {
    id: "10",
    description: "Sass",
    images: {
      large: sassLarge,
      medium: sassMedium,
      small: sassSmall,
    }
  },
  {
    id: "11",
    description: "JQuerry",
    images: {
      large: jquerryLarge,
      medium: jquerryMedium,
      small: jquerrySmall,
    }
  },
  {
    id: "12",
    description: "Boostrap",
    images: {
      large: boostrapLarge,
      medium: boostrapMedium,
      small: boostrapSmall,
    }
  },
  {
    id: "13",
    description: "TypeScript",
    images: {
      large: typescriptLarge,
      medium: typescriptMedium,
      small: typescriptSmall,
    }
  },

  
  // Ajoutez d'autres objets pour les autres logos
];

export default Logo_datas;


