import React from "react";


export default function Card({title, cover, description}) {

	return (
		<section className="projets">
			<div className="presentation_projet">
				
					<img src={cover} className="flat" alt={title}  loading="lazy" />
					<div className="title-wrapper">
					<h4>{title}</h4>	
					</div>	
				
			</div>
			<div className="projet_title">
				<p className="Projet_text">{description}</p>
			</div>
			
		</section>
		
	)
}