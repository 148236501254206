import React, { useEffect, useState, useMemo } from 'react';
import gsap from 'gsap';

function Animation() {
    
    const texts = useMemo(() => [
        'Elia DE FAYS, Bienvenue !',
        'Elia DE FAYS, Developpeuse Web',
        'Elia DE FAYS, front + back'
    ], []);

    const [currentText, setCurrentText] = useState(texts[0]);

    useEffect(() => {
        const animateText = () => {
            let textAnimation = gsap.timeline({ onComplete: () => gsap.set('.letter', { clearProps: "all" }) });
            textAnimation.from('.letter', {
                y: 100,
                opacity: 0,
                stagger: {
                    each: 0.05
                },
                ease: "customEase"
            });
        };

        // Changer le texte actuel à chaque relancement de l'animation
        setCurrentText(texts[Math.floor(Math.random() * texts.length)]);

        // Démarrez l'animation initiale
        animateText();

        // Répéter l'animation toutes les 10 secondes
        const intervalId = setInterval(() => {
            animateText();
            setCurrentText(texts[Math.floor(Math.random() * texts.length)]);
        }, 10000);

        // Nettoyage de l'intervalle lorsque le composant est démonté
        return () => clearInterval(intervalId);
    }, [texts]);

    return (
        <div className="animation">
            <div className="container-Animation">
                {
                    currentText.split('').map((letter, index) => (
                        <div className="letter" key={index}>{letter === ' ' ? '\u00A0' : letter}</div>
                    ))
                }
            </div>
        </div>
    );
}

export default Animation;
