import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';


import './styles/SASS-CRACH/scss/_Carousel.scss'
import './styles/SASS-CRACH/scss/_ImageWithModals.scss'
import './styles/SASS-CRACH/scss/_Error.scss'
import './styles/SASS-CRACH/scss/_header.scss'
import './styles/SASS-CRACH/scss/_Accueil.scss'
import './styles/SASS-CRACH/scss/style.scss'
import './styles/SASS-CRACH/scss/_gallery.scss'
import './styles/SASS-CRACH/scss/_banner.scss'
import './styles/SASS-CRACH/scss/_footer.scss'
import './styles/SASS-CRACH/scss/_collapse.scss'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<App  />
	</React.StrictMode>
);