import React, { useState, useEffect } from 'react';

import bureauSmall from "../../assets/ProjetsPhotos/bureau_Small.webp";
import bureauMedium from "../../assets/ProjetsPhotos/bureau_Medium.webp";
import bureauLarge from "../../assets/ProjetsPhotos/bureau_Large.webp";


import plus from "../../assets/ProjetsPhotos/plus.svg"
import frimousse from "../../assets/ProjetsPhotos/frimousse .webp"

const ImageWithModals = () => {
  const [selectedModal, setSelectedModal] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleModalOpen = (modalIndex) => {
    setSelectedModal(modalIndex);
    // Ajoute la classe 'modal-open' au corps pour bloquer le défilement de l'arrière-plan
    document.body.classList.add('modal-open');
  };

  const handleModalClose = () => {
    setSelectedModal(null);
    // Supprime la classe 'modal-open' du corps pour rétablir le défilement de l'arrière-plan
    document.body.classList.remove('modal-open');
  };

  let backgroundImg;
  if (windowWidth < 768) {
    backgroundImg = bureauSmall;
  } else if (windowWidth < 1024) {
    backgroundImg = bureauMedium;
  } else {
    backgroundImg = bureauLarge;
  }

  
  const aboutMeDatas = [
		{
			"id": "1",
			"title": "A propos de moi",
			"content": [ 
        
        "Je bénéficie d'une expérience de 22 ans dans le domaine de la Recherche Universitaire. Ma spécialité précédente était la biologie moléculaire et cellulaire (Bac+3). Au cours de ce parcours, j'ai pu développer ma curiosité intellectuelle, ma capacité à proposer de nouvelles idées en restant à l'écoute des dernières technologies, ainsi que mon désir d'apprendre en permanence. La rigueur et la minutie nécessaires dans ce domaine peuvent être aisément transposées dans le domaine du développement.",
        "En 2023, j'ai décidé d'explorer une nouvelle carrière. J'ai suivi une formation de 3 mois en autodidacte, avant de m'engager dans un parcours diplômant de 7 mois en tant que \"développeur web\" chez OpenClassrooms.",
        "Les débuts ont été difficiles pour apprendre de nouveaux langages, mais la machine commence à s'emballer. Vous vous trouvez actuellement sur ce portfolio, qui n'est pas un modèle préconçu, mais bien le fruit de mon travail."
      ]
		},
		{
			"id": "2",
			"title": " Ma frimousse",
			"content": [ 
				<img className="frimousse" src={frimousse} alt="portrait" loading="lazy" />,
        "Ce site a été conçu pour donner un aperçu de mes connaissances en UI design, design UX, design responsive et Green IT"
		]
		},
		{
			"id": "3",
			"title": " Mes passions",
			"content": [ 
				" J'aime rénové des maisons de mes propres mains, et j'ai pu développé un intérêt pour le secteur du bâtiment. Cette passion m'a conduite à fonder ma propre entreprise, conciliant cette aventure avec mon poste de fonctionnaire jusqu'en 2021, démontrant ainsi ma capacité à entreprendre.",
				"En tant qu'entrepreneure, j'ai acquis une maîtrise du SEO, et utilisé des logiciels de design. Cependant, la frustration de ne pas pouvoir concrétiser toutes mes idées à ma guise a été un moteur pour ma reconversion vers le développement web."
				
		]
		},
		{
			"id": "4",
			"title": " Derniers diplômes",
			"content": [ 
				" Formation de developpeur web (2023-2024) (OpenClassrooms)",
        " Titulaire d'une licence professionnelle (2018-2019) en génétique moléculaire et biologie cellulaire",
        " BTS biophysique (2000)",
        " BAC STL option BGB (biochimie et génie biologique)_1999"

				
				
		]
		},
    {
			"id": "5",
			"title": " Hobbies",
			"content": [ 
				" Bricolage (maçonnerie, électricité, domotique, second oeuvre, étanchéité)",
				" Retrouvez-moi dans ces endroits: jeux grandeux nature (GN) et murder party, convention cosplay, musée , châteaux. Prochainement dans un hackethon",
        " Adepte de city-trips",
        " Fan de drama coréen"
				
		]
		},
    {
			"id": "6",
			"title": " Langues",
			"content": [ 
				" Anglais: niveau B2",
				" Allemand : niveau A2(niveau scolaire)"
        
				
		]
		}
		
	]

  return (

    <div className="image-with-modals">
      <h3>Qui suis-je?</h3>
      <div className="background-image" style={{ backgroundImage: `url(${backgroundImg})` }}>
        {/* Image de fond */}
        <div className="icon-buttons">
          {/* Boutons icônes pour ouvrir les modales */}
          {[1, 2, 3, 4, 5, 6].map((index) => (
            <img
              key={index}
              src={plus} 
              alt={`logo plus ${index}`}
              className={`plus-${index}`}
              onClick={() => handleModalOpen(index)}
              loading="lazy"
            />
          ))}
        </div>
      </div>
      {/* Modals */}
      {selectedModal !== null && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={handleModalClose}>X</span>
            <h4>{aboutMeDatas[selectedModal - 1].title}</h4>
            {aboutMeDatas[selectedModal - 1].content.map((item, index) => (
              <p key={index}>{item}</p>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageWithModals;