import React from 'react';
import githubIcon from "../../assets/ProjetsPhotos/github.svg";
import linkedinIcon from "../../assets/ProjetsPhotos/linkedin.svg";

function Footer() {
    return (
        <div className="containerFooter">
             <div className="socialIcons">
                <a href="https://github.com/helia57" target="_blank" rel="noopener noreferrer">
                    <img src={githubIcon} alt="GitHub" loading="lazy"/>
                </a>
                <a href="https://www.linkedin.com/in/elia-de-fays-082917173/?originalSubdomain=fr" target="_blank" rel="noopener noreferrer">
                    <img src={linkedinIcon} alt="LinkedIn" loading="lazy"/>
                </a>
            </div>
            <p className="text_Footer">
                Made With <span role="img" aria-label="heart">  _❤_  </span> by Elia De Fays, with React JS 
            </p>
            <p className="text_Footer">Copyright &copy; 2024, eliadefays.fr
            </p>
           
        </div>
    );
}

export default Footer;